.badge-dot.badge-dot-sm i{
    width: 2.2rem;
    height: 2.2rem;
    padding: 0.4rem;
}

.info-color-badge {
    border-radius: 0.4rem;
    padding: 1.6rem 2rem;
    color: $info;
    background-color: rgba($info, 0.1);
    font-size: 1.4rem;
}

.series-legend-badge {
    position: relative;

    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;

    line-height: 1;
    font-size: 1.2rem;
    font-weight: 600;

    color: $default;
    background: transparent;

    margin: 0.4rem;
    padding: 0.6rem 0.8rem;

    border: none;

    &:not(.disabled)::before {
        content: "";
        background-color: var(--data-color);
        opacity: 0.1;
        position: absolute;
        border-radius: 0.8rem;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 0;
    }

    & > span {
        z-index: 1;
    }

    &.disabled {
        color: #8d9cae;
        background-color: #eaecef;
        border-radius: 0.8rem;
    }

    &:focus-visible,
    &:focus {
        outline: none;
    }

    .series-color {
        height: 0.8rem;
        width: 0.8rem;
        margin-right: 0.8rem;
        border-radius: 50%;
        background-color: #e0e0e0;
    }
}

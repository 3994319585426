@media all and (min-width: 768px) {
    .app-avatar {
        min-height: 10rem;
    }
}

.virtual-agents-list {
    padding: 0 2rem;

    .va-shortcut {
        padding-top: 1rem;
        padding-bottom: 2rem;
        margin: 0.5rem 0;
        border-radius: 0.5rem;
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .va-logo {
            width: 6rem;
            height: 7rem;
            z-index: 2;

            &.james_avatar {
                margin-left: 0.9rem;
            }

            &.becky_avatar {
                margin-left: 1.2rem;
            }

            &.frank_avatar {
                margin-left: 0.6rem;
            }

            &.mia_avatar {
                width: 6.7rem;
                margin-left: 1.2rem;
            }

            &.bob_avatar {
                margin-left: 1rem;
            }

            &.andy_avatar {
                margin-left: 0.5rem;
            }
        }

        small {
            z-index: 2;
            display: block;
            margin-top: 1rem;
            font-size: 1.4rem;
            font-weight: 600;
            min-width: 40px;
            color: $default;
            border-bottom: 3px solid black;
        }

        &.va-selected small {
            color: white;
            padding: 0.1rem 1rem;
            padding-bottom: 0.2rem;
            border-radius: 5rem;
            border-bottom: 0px;
            background-color: $primary;
            cursor: default;
        }

        &.disabled {
            opacity: 0.4;
        }
    }
}

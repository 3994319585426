.date-reference-container {
    min-width: 40rem;
}

.notifications-container {
    max-height: 30rem;
    overflow-y: auto;
}

.forecast-container {
    min-height: 28rem;
}

.correlated-commodities-container {
    max-height: 47rem;
    min-height: 47rem;
    overflow: auto;
    position: relative;

    &.in-compare {
        min-height: 44rem;
        max-height: 44rem;
    }

    &.disable-scroll {
        overflow: hidden !important;
    }
}

.examine-card-container {
    flex: 1 1 35rem;
}

.commodity-legend-container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin: -2rem;
    margin-top: -1rem;
    margin-bottom: 1rem;
    padding: 0rem 1.5rem 1rem;
}

.period-step-container {
    min-height: 16rem;
}

.consumptions-override-box {
    overflow: scroll;
    padding-bottom: 2rem;
}
form.ng-submitted {
    input.ng-invalid:not(:focus) {
        border-color: var(--red);

        & + .input-group-append {
            .input-group-text {
                border-color: var(--red);
            }
        }
    }
}

.valid-feedback,
.warning-feedback,
.invalid-feedback {
    position: absolute;
    display: block;
}

.modal-header {
    align-items: center;
    border-bottom: 1px solid #e9ecef;
    h6 {
        font-weight: bold;
    }
}

.modal-footer {
    border-top: 1px solid #e9ecef;
}

.modal-dialog {
    margin: auto !important;
}

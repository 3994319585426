.accordion-details {
    .card-header {
        padding: 1.2rem 2rem;
        position: relative;
    }

    .btn.btn-link {
        width: 100%;

        &:not(.collapsed)::after {
            transform: translate(0%, -50%) rotate(180deg);
        }

        &::after {
            content: "\f078";
            font-weight: 700;
            font-family: var(--fa-font);
            font-size: 1.5rem;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0%, -50%);
            transition: all .2s ease-out;
        }
    }
}

.main-content {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    // Navbar
    .navbar-top {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background-color: transparent;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    main {
        flex-grow: 1;
        margin-top: $header-main-distance - 50px;

        @include media-breakpoint-up(md) {
            padding-top: 220px;
            margin-top: -$header-main-distance;
        }
    }

    // Container
    .container-fluid {
        @include media-breakpoint-up(md) {
            padding-left: ($main-content-padding-x + $grid-gutter-width * 0.5) !important;
            padding-right: ($main-content-padding-x + $grid-gutter-width * 0.5) !important;
        }
    }
}


.main-content-public {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    // Navbar
    .navbar-top {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background-color: transparent;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    main {
        flex-grow: 1;
    }
}


// Offsets the main content depending on the sidebar positioning

.navbar-vertical.navbar-expand {
    @each $breakpoint, $dimension in $grid-breakpoints {
        &-#{$breakpoint} {
            @include media-breakpoint-up(#{$breakpoint}) {
                // Left
                &.fixed-left + .main-content {
                    margin-left: $navbar-vertical-width;
                } // Right
                &.fixed-right + .main-content {
                    margin-right: $navbar-vertical-width;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    html:not(.auth-layout) .main-content {
        margin-left: $navbar-vertical-width;
    }
  }

  .fixed-right{
    .navbar-vertical.navbar-expand-md{
      right: 0;
      border-width: 0 0 0 1px;
    }
     .main-content {
      margin-right: $navbar-vertical-width;
      margin-left: 0;
    }
  }


  @media screen and (max-width: 568px){
    html:not(.auth-layout) .main-content {
        margin-left: 0;
    }
  }


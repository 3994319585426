.commodities-chart {
    min-height: 15rem;
}

.chart.chart-center {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.commodities-default-chart {
    min-height: 13rem;
}

.short-commodity-chart {
    .commodity-chart {
        min-height: 25rem;
    }
}

.commodity-chart {
    min-height: 51rem;
}

.simulation-chart-container {
    min-height: 51rem;
    overflow-x: auto;
    
    .simulation-chart {
        min-height: 51rem;
    }
}

.ROI-chart {
    min-height: 47rem;
}

.performance-gauge-chart {
    min-height: 25rem;
    overflow: hidden;
}

.performance-gauge-container {
    height: 9em;
}

.performance-time-delta-chart,
.performance-time-series-chart {
    height: 24rem;
    position: relative;
}

.consumption-expected-chart {
    width: 100%;
    height: 20rem;
}
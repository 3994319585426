.card-checkbox {

    .custom-control-label {
        &::after,
        &::before {
            display: none;
        }

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        background-color: #f3f2ee;
        cursor: pointer;
        border-radius: 4px;
        padding: 2.5rem;
        margin-bottom: 1rem;
        z-index: 1;

        transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;

        &:hover {
            box-shadow: 0px 12px 17px -7px rgb(0 0 0 / 30%);
            transform: translateY(-5px);
        }

        fa-icon {
            font-size: 3.2rem;
            line-height: 5.5rem;
            margin-bottom: 1rem;
        }
        p{
            font-size: 2rem;
            font-weight: 600;
            letter-spacing: .1rem;
            margin-bottom: .5rem;
        }
    }

    input[checked="true"] + .custom-control-label{
        color: $primary;
    }

}

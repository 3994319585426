.header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $header-height;
}

.bg-gradient-header {
    background: linear-gradient(117deg, theme-color('primary') 0, theme-color('primary') 100%) !important;
}

@import "@fortawesome/fontawesome-pro/scss/fontawesome";
@import "@fortawesome/fontawesome-pro/scss/duotone.scss";
@import "@fortawesome/fontawesome-pro/scss/_duotone-icons.scss";

@import "@fortawesome/fontawesome-pro/scss/brands";
@import "@fortawesome/fontawesome-pro/scss/light";
@import "@fortawesome/fontawesome-pro/scss/regular";
@import "@fortawesome/fontawesome-pro/scss/solid";
@import "@fortawesome/fontawesome-pro/scss/thin";


:root {
    --fa-font: "Font Awesome 6 Pro"
}

.fa-primary {
    color: gray
}

fa-icon.active,
fa-duotone-icon.active {
    .fa-secondary {
        fill: $primary;
        opacity: .9;
    }
}
.breadcrumb-navbar {
    min-width: 0;
}

.breadcrumb-container {
    @include media-breakpoint-up(xl) {
        flex-wrap: nowrap !important;
    }
    flex-wrap: wrap;
    display: flex;
    align-items: center;
}

.breadcrumb {
    @include media-breakpoint-down(sm) {
        display: none;
    }
    flex-wrap: nowrap;
    min-width: 0;
}

.breadcrumb-item {
    font-size: 1.5rem;
    min-width: 0;
}

.breadcrumb-dark {
    background-color: #172b4d
}

.breadcrumb-dark .breadcrumb-item {
    font-weight: 600
}

.breadcrumb-dark .breadcrumb-item a {
    color: #f6f9fc
}

.breadcrumb-dark .breadcrumb-item a:hover {
    color: #fff
}

.breadcrumb-dark .breadcrumb-item+.breadcrumb-item::before {
    color: #adb5bd
}

.breadcrumb-dark .breadcrumb-item.active {
    color: #dee2e6;
    font-weight: 200;
    a{
        cursor: default;
    }
}

.breadcrumb-links {
    padding: 0;
    margin: 0;
    background: 0 0
}
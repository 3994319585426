.card-body {
    .card-body-container {
        padding: 1.5rem 2rem;
        margin-bottom: 0;
        background-color: #fff;

        &:not(:first-child) {
            border-top: 1px solid rgba(0, 0, 0, 0.05);
        }

        .container-section {
            padding: 1.5rem 2rem;

            &:not(:first-child) {
                border-left: 1px solid rgba(0, 0, 0, 0.05);
            }
        }
    }
}

.flipping-card-container {
    -webkit-perspective: 100rem;
    perspective: 100rem;

    .flipping-card {
        position: relative;
        width: 100%;
        // height: 35rem;
        height: 33rem;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transform: transform 1s;
        -ms-transform: transform 1s;
        transform: transform 1s;
    }

    .card-side {
        width: 100%;
        height: 100%;
        position: absolute;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .card-side-front {
        transform: rotateY(0deg);
    }

    .card-side-back {
        transform: rotateY(-180deg);
    }
}

.card-preview {
    display: flex;
    flex-direction: column;

    // &-body{
    // }

    &-footer,
    &-placeholder {
        width: 100%;
        flex-grow: 1;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    &-footer {
        background-color: #fff; // rgba($blue, 0.7);
    }

    &-placeholder {
        background-color: rgba(#e5e5e5, 0.4);
    }
}

.forecast-card {
    border-bottom: 4px solid #e0e0e0;
    min-width: 20%;
    // min-width: calc(100vw / 5 - 10rem);

    &:not(:first-child) {
        margin-left: 2rem;
    }

    &.active {
        border-bottom-color: $primary;
        // transform: ;
    }
}

.wizard-card {
    &-header {
        padding-top: 2.5rem;
        padding-bottom: 4rem;
        text-align: center;
    }
}

.category-card {
    border-bottom: 4px solid #e9ecef;
    height: 33rem;

    .category-card-description {
        white-space: break-spaces;
        text-align: center;
    }
}

.card-selected {
    border-bottom: 4px solid $primary
}

.card-rounded {
    border: 1px solid #CED4DA;
    border-radius: 8px;
    padding: 2rem;

    .card-rounded-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .card-rounded-index {
            background-color: rgba($primary, 0.1);
            color: $primary;
            border-radius: 8px;
            padding: 0.2rem 1rem;
            margin-right: 1rem;
        }

        .card-rounded-title {
            font-size: 1.5rem;
            font-weight: 500;
        }
    }

    .card-rounded-item {
        font-size: 1.4rem;
        margin-top: 1.5rem;

        .card-rounded-item-title::first-letter {
            text-transform: capitalize;
        }

        .card-rounded-item-value {
            font-weight: 600;
        }
    }
}

.simulation-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem;
    padding-top: 0;

    .simulation-item {
        font-size: 1.4rem;
        padding-top: 2rem;

        .simulation-item-title::first-letter {
            text-transform: capitalize;
        }

        .simulation-item-value {
            font-weight: 600;
        }
    }
}
.table td>span {
    white-space: normal;
}

// TODO fix this when introduce sortable columns
.header-column[role="button"] {
    cursor: default;
}

.commodities-table-container {
    --table-max-height: 60rem;

    .commodities-table {

        td,
        th {
            border-left: 0;
            border-right: 0;
        }

        th {
            padding: 1.2rem 1.6rem !important;
            font-size: 1.2rem !important;
        }

        td {
            padding: 0.8rem 1.6rem !important;
            font-size: 1.4rem !important;

            &.commodity-name-column {
                min-width: 28rem;
                max-width: 28rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        tbody {

            tr {
                &:first-child {

                    td,
                    th {
                        border-top: 0;
                    }
                }

                &:last-child {

                    td,
                    th {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}

.supply-table,
.materials-table {

    td,
    th {
        border-left: 0;
        border-right: 0;
    }

    th {
        padding: 1.2rem 1.6rem !important;
        font-size: 1.2rem !important;
        white-space: normal;
        min-width: 9rem;
        max-width: 12rem;
        vertical-align: middle !important;
    }

    td {
        padding: 0.8rem 1.6rem !important;
        font-size: 1.4rem !important;

        >span {
            white-space: nowrap;
        }

        &.material-name-column {
            min-width: 28rem;
            max-width: 28rem;
        }

        &.actions-column {
            width: 9rem;
            min-width: 9rem;
            max-width: 9rem;
        }
    }

    tbody {
        tr {
            &:first-child {

                td,
                th {
                    border-top: 0;
                }
            }

            &:last-child {

                td,
                th {
                    border-bottom: 0;
                }
            }
        }
    }
}

.simulation-table {

    td,
    th {
        border-left: 0;
        border-right: 0;
    }

    th {
        padding: 1.2rem 1.6rem !important;
        font-size: 1.2rem !important;
        white-space: normal;
        min-width: 9rem;
        max-width: 12rem;
        vertical-align: middle !important;
    }

    td {
        padding: 0.8rem 1.6rem !important;
        font-size: 1.3rem !important;

        >span {
            white-space: nowrap;
        }

        &.simulation-name-column {
            min-width: 28rem;
            max-width: 28rem;
        }

        &.supplier-name-column {
            min-width: 24rem;
            max-width: 24rem;
        }

        &.actions-column {
            width: 9rem;
            min-width: 9rem;
            max-width: 9rem;
        }

        &.simulation-bg-column {
            background-color: rgba(#1e90ff, 0.1);
        }
    }

    tbody {
        tr {
            &:first-child {

                td,
                th {
                    border-top: 0;
                }
            }

            &:last-child {

                td,
                th {
                    border-bottom: 0;
                }
            }
        }
    }
}

.simulation-detail-table {

    td,
    th {
        border-left: 0;
        border-right: 0;
    }

    th {
        font-size: 1.2rem !important;
        white-space: normal;
        min-width: 8rem;
        max-width: 8rem;
        vertical-align: middle !important;
    }

    td {
        padding: 0.8rem 1.6rem !important;
        font-size: 1.3rem !important;
        min-width: 8rem;
        max-width: 8rem;

        >span {
            white-space: nowrap;
        }

        &.name-column {
            min-width: 22rem;
            max-width: 22rem;
        }
    }

    tbody {
        tr {
            &:first-child {

                td,
                th {
                    border-top: 0;
                }
            }

            td {
                padding: 2.1rem !important;
            }

            &:last-child {

                td,
                th {
                    border-bottom: 0;
                }
            }
        }
    }
}
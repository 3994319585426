.placeholder-image {
    &.xl {
        width: 35rem;
        height: 35rem;
    }

    &.lg {
        width: 30rem;
        height: 30rem;
    }

    &.md {
        width: 24rem;
        height: 24rem;
    }

    &.sm {
        width: 18rem;
        height: 18rem;
    }

    height: 30rem;
    width: 30rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
    margin: 2rem 3rem 2rem 1rem;

    &.under-construction{
        background-image: url(/assets/images/pages/under-construction.svg);
    }

    &.examine{
        background-image: url(/assets/images/pages/examine.svg);
    }
    
    &.buy{
        background-image: url(/assets/images/pages/buy.svg);
    }
    
    &.no-forecasts{
        background-image: url(/assets/images/pages/no-forecasts.svg);
    }

    &.ROI{
        background-image: url(/assets/images/pages/ROI.svg);
    }

    &.performance{
        background-image: url(/assets/images/pages/performance.svg);
    }
}

.report-image-preview {
    max-width: 100%;
    max-height: 45rem;
}

.becky-image {
    max-width: 8rem;
    width: 100%;
    min-height: 12rem;
}


.info-icon {
    color: inherit;
    cursor: pointer;
}

.bell-counter {
    position: absolute;
    top: 3px;
    left: 0;
    font-size: 1rem;
    background-color: white;
    color: #555;
    font-weight: bold;
    border-radius: 30px;
    padding: 0 0.5rem;
}
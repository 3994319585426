.skltn-card {
    flex: 1;
    background: #fff;
    height: 100%;

    &-avatar {
        width: 50px;
        height: 50px;
        margin-bottom: 20px;

        &-sm{
            width: 30px;
            height: 30px;
        }

        &-md{
            width: 40px;
            height: 40px;
        }

        &-lg{
            width: 50px;
            height: 50px;
        }
    }

    &-paginator-number {
        height: 36px;
        width: 36px;
    }

    &-title {
        height: 24px;
        margin-bottom: 20px;
    }

    &-input{
        height: 34px;
    }

    &-btn {
        height: 35px;
        width: 95px;
    }

    &-progressbar {
        height: 5px;
        width: 100%;
    }

    &-line {
        height: 12px;
        margin-bottom: 12px;

        &:last-child {
            width: 60%;
        }
    }

    &-title,
    &-line {
        @for $i from 1 through 20 {
            &.w-#{$i * 5} {
                width: $i * 5%;
            }
        }
    }
}

.skltn-picture {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;
    &-right {
        flex-direction: row-reverse;
    }
    &-img {
        width: 70px;
        height: 50px;
    }
    &-caption {
        width: calc(100% - 90px);
    }
    &-line {
        height: 16px;
        margin-top: 16px;
        &:first-child {
            margin-top: 0;
        }
    }
}

.skltn-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 2.4rem;
    &-right {
        flex-direction: row-reverse;
    }
    &-checkbox {
        width: 2rem;
        height: 2rem;
    }
    &-caption {
        width: calc(100% - 3.2rem);
        margin-top: 0.2rem;
        padding-right: 1rem;
    }
    &-line {
        height: 1.4rem;
        margin-top: 1.8rem;
        &:first-child {
            margin-top: 0;
        }
    }
}

.skltn-toggle {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    &:not(:last-child) {
        margin-bottom: 2rem;
    }

    &-toggle {
        width: 4.8rem;
        height: 2.2rem;
    }
    &-caption {
        width: calc(100% - 3.2rem);
        max-width: 12rem;
        height: 2rem;
        margin-top: 0.2rem;
        padding-right: 4rem;
    }
    &-line {
        height: 1.8rem;
        margin-top: 1.8rem;
        &:first-child {
            margin-top: 0;
        }
    }
}

.skltn-line {
    height: 26px;
    margin-bottom: 16px;
}

@media (max-width: 640px) {
    .skltn-card {
        display: flex;
        flex-flow: row wrap;
        &-body {
            flex: auto;
        }
        &-avatar {
            width: 60px;
            height: 60px;
            margin: 0 20px 0 0;
        }
        &-title {
            height: 32px;
            margin-bottom: 20px;
        }
        &-line {
            height: 20px;
            margin-bottom: 20px;
        }
    }
}

.skltn-card-avatar.small{
    height: 3.5rem;
    width: 3.5rem;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
    background-color: #e9ecef;
    border-color: #e9ecef;
    font-weight: 500;
}

label.btn-secondary {
    font-weight: 500;
}

.btn-buy-commodity {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    white-space: nowrap;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;

    &:hover {
        transform: translate(-50%, -3px) !important;
    }
}

.btn-granularity {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
}

.btn-bookmark {
    position: absolute;
    top: -1.5rem;
    right: 1rem;
    font-size: 3rem;
    color: $gray-400;

    &:focus{
        box-shadow: none !important;
    }

    &.active{
        color: lighten($blue, 10%);
    }
}

.btn-link {
    font-weight: normal !important;
    white-space: nowrap;
}

.btn-link.primary.active {
    color: white !important;
    background-color: $primary;
}

.btn-link.active {
    background-color: $gray-200;
}

.btn-consumption-and-purchases {

    &:first-child {
        border-left: 1px solid $gray-200;
    }

    border: 1px solid $gray-200;
    border-left: 0;
    
    color: $gray-600;

    &.active {
        background-color: rgba($primary, .1);
        color: $primary;
    }
    
}